(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/constants.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const drawStates = {
  UNDEFINED: 0,
  DEFINED: 1,
  OPENED: 2,
  REGCLOSED: 3,
  CANCELCLOSED: 4,
  RESULT_ENTERED: 5,
  RESULT_VERIFIED: 6,
  WINCOUNT_STARTED: 7,
  WINCOUNT_FAILED: 8,
  WINCOUNT_DONE: 9,
  WINVALUES_ENTERED: 10,
  WINVALUES_VERIFIED: 11,
  WINGEN_STARTED: 12,
  WINGEN_FAILED: 13,
  WINGEN_DONE: 14,
  WINPAYOUT_STARTED: 15,
  WINPAYOUT_FAILED: 16,
  PUBLISH_STARTED: 17,
  PUBLISH_FAILED: 18,
  PUBLISH_DONE: 19,
  FINALIZED: 20
};

const raceDrawStates = {
  UNDEFINED: 0,
  DEFINED: 1,
  OPENED: 2,
  REGCLOSED: 3,
  CANCELCLOSED: 4,
  RESULT_ENTERED: 5,
  RESULT_VERIFIED: 6,
  FINALIZED: 7
};

const raceStatuses = {
  UNDEFINED: undefined,
  START: 'start',
  STOPPED: 'stopped',
  RUNNERS_READY: 'runnersReady',
  JOCKEY_CHANGE: 'jockeyChange',
  RUNNERS_ON_TRACK: 'runnersOnTrack',
  START_IN_3_MINUTES: 'startIn3Minutes',
  RUNNERS_WAITING_FOR_START: 'runnersWaitingForStart',
  FALSE_START: 'falseStart',
  STARTED: 'started',
  RESULT_ENTERED: 'resultEntered',
  RESULT_ENTERED_PHOTO: 'resultEnteredPhoto',
  RESULT_ENTERED_VALIDATED: 'resultEnteredValidated',
  RESULT_OFFICIAL: 'resultOfficial',
  RESULT_OFFICIAL_PHOTO: 'resultOfficial',
  RACE_FINALIZED: 'raceFinalized',
  CANCELLED: 'cancelled'
};

const finishPlaceReasons = {
  UNDEFINED: undefined,
  DISQUALIFIED: 'disqualified',
  BEATEN: 'beaten',
  TURNED_BACK: 'turnedBack',
  STOPPED: 'stopped',
  RUN_OUT: 'runOut',
  DISTANCED: 'distanced',
  REMAINED_AT_THE_POST: 'remainedAtThePost',
  FALLEN: 'fallen',
  FINISHED: 'finished'
};
const gameTypes = {
  PLATS: 'plats',
  TOPP2: 'topp2',
  RX2IO: 'rx2io',
  RX2P: 'rx2p',
  RX2P4: 'rx2p4',
  RX4IO: 'rx4io',
  TOPP5: 'topp5',
  VINNARE: 'vinnare',
  RX3IOAO: 'rx3ioao',
  RX3AO: 'rx3ao',
  RX3IO: 'rx3io'
};

const gameTypesList = Object.values(gameTypes);
const gameTypeToSubProductId = {
  [gameTypes.VINNARE]: 'RX1W',
  [gameTypes.PLATS]: 'RX1P',
  [gameTypes.TOPP2]: 'RX2AO',
  [gameTypes.RX2IO]: 'RX2IO',
  [gameTypes.RX2P]: 'RX2P',
  [gameTypes.RX2P4]: 'RX2P4',
  [gameTypes.RX4IO]: 'RX4IO',
  [gameTypes.TOPP5]: 'RX5IO',
  [gameTypes.RX3IOAO]: 'RX3IOAO',
  [gameTypes.RX3AO]: 'RX3AO',
  [gameTypes.RX3IO]: 'RX3IO'
};

const subProductIdToGameType = Object.keys(gameTypeToSubProductId).reduce((acc, key) => _objectSpread(_objectSpread({}, acc), {}, {
  [gameTypeToSubProductId[key]]: key
}), {});
const gameTypeToUrlName = {
  [gameTypes.VINNARE]: 'vinnare',
  [gameTypes.PLATS]: 'plats',
  [gameTypes.TOPP2]: 'duo',
  [gameTypes.RX2IO]: 'duo-rak',
  [gameTypes.RX2P]: '2-av-3',
  [gameTypes.RX2P4]: '2-av-4',
  [gameTypes.RX4IO]: 'high-4',
  [gameTypes.TOPP5]: 'high-five',
  [gameTypes.RX3IOAO]: 'high-3',
  [gameTypes.RX3AO]: 'trippel',
  [gameTypes.RX3IO]: 'trippel-rak'
};
const urlNameToGameType = Object.keys(gameTypeToUrlName).reduce((acc, key) => _objectSpread(_objectSpread({}, acc), {}, {
  [gameTypeToUrlName[key]]: key
}), {});
const racePickerModes = {
  ALL: 'all',
  HOT: 'hot',
  TIMELINE: 'timeline'
};

const raceDisciplines = {
  FLAT: 'flat',
  HURDLES: 'hurdles',
  TROTTING: 'trotting',
  STEEPLECHASE: 'steeplechase',
  OBSTACLE: 'obstacle',
  HARNESSED: 'harnessed',
  CROSS: 'cross',
  RIDDEN: 'ridden'
};
const gaits = {
  GALLOP: 'gallop',
  TROTTING: 'trotting'
};
const oddsTrends = {
  UP: 'up',
  DOWN: 'down'
};

const startTypes = {
  AUTO: 'auto',
  VOLT: 'volt',
  STALLS: 'stalls'
};
const shoeTypes = {
  FRONT: 'front',
  REAR: 'rear',
  BOTH: 'both'
};
const infoTypes = {
  BLINKERS: 'blinkers',
  CURRENCY: 'currency',
  DISTANCE: 'distance',
  IO_DISTRIBUTION: 'ioDistribution',
  LAST_PERFORMANCE: 'lastPerformance',
  ODDS_LATEST: 'oddsLatest',
  ODDS_REF: 'oddsRef',
  SHOW_ODDS: 'showOdds',
  PERCENT: 'percent',
  RESULTS: 'results',
  START_GATE: 'startGate',
  SHOES: 'shoes',
  STRING: 'string',
  WEIGHT: 'weight'
};

const sexTypes = {
  FEMALE: 'female',
  GELDING: 'gelding',
  MALE: 'male'
};

const coatColors = {
  CHESTNUT: 'CHESTNUT',
  CHESTNUT_ROAN: 'CHESTNUT ROAN',
  BAY: 'BAY',
  BROWN: 'BROWN',
  BAY_ROAN: 'BAY ROAN',
  LIVER_CHESTNUT: 'LIVER CHESTNUT',
  LIGHT_GREY: 'LIGHT GREY',
  GREY: 'GREY',
  BLACK: 'BLACK',
  ROAN: 'ROAN'
};
const couponValues = {
  CANCELED: 'na'
};
const systemTypes = {
  UNIT: 'unit',
  UNIT_COMBINED: 'unitCombined',
  UNIT_COMBINED_UNORDERED: 'unitCombinedUnordered',
  TOP_RANK: 'topRank',
  TOP_RANK_PIX: 'topRankPix',
  WHEEL: 'wheel',
  RESULT: 'result'
};
const pixModes = {
  FROM_SCRATCH: 'from-scratch',
  CUSTOM: 'custom'
};
const systemTypesToCssName = {
  [systemTypes.UNIT]: 'unit',
  [systemTypes.UNIT_COMBINED]: 'unit-combined',
  [systemTypes.UNIT_COMBINED_UNORDERED]: 'unit-combined-unordered',
  [systemTypes.TOP_RANK]: 'top-rank',
  [systemTypes.TOP_RANK_PIX]: 'top-rank',
  [systemTypes.WHEEL]: 'wheel',
  [systemTypes.RESULT]: 'result'
};
const couponMenuItems = {
  COUPON: 'coupon',
  MOST_PLAYED: 'most-played',
  PIX: 'pix',
  SYSTEM: 'system',
  QUINELLA_ODDS: 'odds',
  PRESS_RATINGS: 'press-ratings'
};

const couponMenuItemsList = Object.values(couponMenuItems);
const statisticsMenuItems = {
  OVERVIEW: 'overblick',
  RACE_HISTORY: 'lopp',
  HORSE_INFO: 'profil'
};

const statisticsMenuItemsList = Object.values(statisticsMenuItems);
const rowValues = {
  NEUTRAL: 1,
  MISSING: 2,
  INCORRECT: 3,
  CORRECT_IN_ORDER: 4,
  CORRECT: 5,
  CORRECT_IRRELEVANT_IO: 6,
  CORRECT_IRRELEVANT_AO: 7
};
const quickpickProps = {
  WEIGHTEDQP: 'WEIGHTEDQP',
  MODIFIEDQP: 'MODIFIEDQP',
  NOQP: 'no-qp',
  PARTIALQP: 'PARTIALQP'
};
const wager = {
  error: {
    BAD_SERIAL: 8163,
    CANCEL_TOO_LATE: 8171,
    REVOKE_ERROR: 3111
  }
};
const urlParts = {
  XPERTEN: 'pix',
  COUPON_MENU: 'kupong',
  TAB: 'flik',
  MODE: 'mode',
  TYPE: 'type',
  STATISTICS_TAB: 'statistik',
  WAGER_EXAMINED: 'examined',
  IS_RETAILER_ADMIN: 'isRetailerAdmin',
  ACTIVATED_DRAW_ID: 'activatedDrawId',
  FROM_PLAY_TOGETHER: 'fromPlayTogether',
  SHOW_LIVE: 'showLive',
  ASSET_ID: 'assetId',
  SHOW_LIVE_REDIRECT: 'showLiveRedirect',
  ROW: 'row',
  PIX_MODE: 'pix-mode'
};

const currencyTypes = {
  EUR: 'EUR',
  HKD: 'HKD',
  SEK: 'SEK',
  USD: 'USD'
};
const raceDirections = {
  COUNTERCLOCKWISE: 'counterclockwise',
  CLOCKWISE: 'clockwise'
};
const trackConditions = {
  SOFT: 'soft',
  GOOD_TO_SOFT: 'good to soft',
  VERY_SOFT: 'very soft',
  GOOD: 'good',
  HARD: 'hard',
  HOLDING: 'holding',
  HEAVY: 'heavy',
  VERY_HEAVY: 'very heavy',
  GOOD_TO_FIRM: 'good to firm',
  FAST: 'fast',
  QUITE_SOFT: 'soft',
  YIELDING: 'soft',
  FIRM: 'hard',
  PSF_LENTE: 'slow',
  PSF_RAPIDE: 'fast',
  PSF_STANDARD: 'normal',
  PSF_TRES_RAPIDE: 'very fast'
};
const pressRatingTypes = {
  FAVOURITES: 'Favourites',
  OUTSIDERS: 'Outsiders',
  OTHERS: 'Others'
};
const rowGenMode = {
  COMBINATIONS: 1,
  PERMUTATIONS: 2,
  UNIQUE_ROWS: 3,
  WHEEL: 4
};
const resultPlaceholders = {
  FAKE_HORSE_NUM: 1000
};
const IGNORE_TEST_MEETINGS = true;
const exported = {
  couponValues,
  coatColors,
  currencyTypes,
  drawStates,
  raceDrawStates,
  finishPlaceReasons,
  gameTypes,
  gameTypesList,
  couponMenuItems,
  couponMenuItemsList,
  infoTypes,
  oddsTrends,
  gaits,
  raceDisciplines,
  racePickerModes,
  raceStatuses,
  rowValues,
  sexTypes,
  shoeTypes,
  startTypes,
  statisticsMenuItems,
  statisticsMenuItemsList,
  systemTypes,
  systemTypesToCssName,
  quickpickProps,
  gameTypeToSubProductId,
  subProductIdToGameType,
  gameTypeToUrlName,
  urlNameToGameType,
  wager,
  urlParts,
  raceDirections,
  trackConditions,
  pixModes,
  pressRatingTypes,
  rowGenMode,
  resultPlaceholders,
  IGNORE_TEST_MEETINGS
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.constants = exported;
}

 })(window);