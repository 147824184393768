(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/common/assets/javascripts/image-options.js') >= 0) return;  svs.modules.push('/components/racing/common/assets/javascripts/image-options.js');

'use strict';

const {
  cdn
} = svs.core.config.data.svsconfig;
const ImageTypes = {
  CouponSilk: 'coupon-silk',
  TipsSilk: 'tips-silk',
  StatisticsSilk: 'statistics-silk',
  StatisticsSilkBig: 'statistics-silk-big',
  PhotoFinish: 'photo-finish',
  Course: 'course'
};
const preferredWidths = {
  [ImageTypes.CouponSilk]: [22, 44, 88],
  [ImageTypes.TipsSilk]: [22, 44, 88],
  [ImageTypes.StatisticsSilk]: [22, 44, 88],
  [ImageTypes.StatisticsSilkBig]: [44, 88, 220],
  [ImageTypes.PhotoFinish]: ['original', 220],
  [ImageTypes.Course]: ['original']
};
const imagesDescription = {
  [ImageTypes.CouponSilk]: {
    altText: 'dress',
    title: 'Dress'
  },
  [ImageTypes.TipsSilk]: {
    altText: 'dress',
    title: 'Dress'
  },
  [ImageTypes.StatisticsSilk]: {
    altText: 'dress',
    title: 'Dress'
  },
  [ImageTypes.StatisticsSilkBig]: {
    altText: 'dress',
    title: 'Dress'
  },
  [ImageTypes.PhotoFinish]: {
    altText: 'photo-finish',
    title: 'Målfoto'
  },
  [ImageTypes.Course]: {
    altText: 'course',
    title: 'Bana'
  }
};
const getImagesExtensions = imageType => {
  switch (imageType) {
    case ImageTypes.CouponSilk:
    case ImageTypes.TipsSilk:
    case ImageTypes.StatisticsSilk:
    case ImageTypes.StatisticsSilkBig:
    case ImageTypes.Course:
      return 'png';
    case ImageTypes.PhotoFinish:
      return 'jpg';
    default:
      return 'png';
  }
};
const getAspectRatio = imageType => {
  switch (imageType) {
    case ImageTypes.CouponSilk:
    case ImageTypes.TipsSilk:
    case ImageTypes.StatisticsSilk:
    case ImageTypes.StatisticsSilkBig:
      return {
        width: '220',
        height: '261'
      };
    case ImageTypes.PhotoFinish:
      return {
        width: '478',
        height: '230'
      };
    case ImageTypes.Course:
      return {
        width: '1024',
        height: '576'
      };
    default:
      return '';
  }
};
const getNginxFormat = format => {
  switch (format) {
    case 'jpg':
      return 'jpeg';
    default:
      return format;
  }
};


const getImageOptions = (filename, formattedDate, imageType, title, altText) => {
  const url = "/pmu/{width}/{extension}/".concat(formattedDate, "/").concat(imagesDescription[imageType].altText, "/").concat(filename, ".").concat(getImagesExtensions(imageType));
  const noscriptUrl = "/pmu/".concat(preferredWidths[imageType][0], "/").concat(getNginxFormat(getImagesExtensions(imageType)), "/").concat(formattedDate, "/").concat(imagesDescription[imageType].altText, "/").concat(filename, ".").concat(getImagesExtensions(imageType));
  return {
    altText: altText || imagesDescription[imageType].altText,
    title: title || imagesDescription[imageType].title,
    fitWrapper: false,
    loadingLazy: false,
    originalImage: false,
    url,
    noscriptUrl,
    availableWidths: preferredWidths[imageType],
    aspectRatio: {
      width: getAspectRatio(imageType).width,
      height: getAspectRatio(imageType).height
    },
    availableFormats: [{
      mimetype: 'image/webp',
      extension: 'webp'
    }, {
      mimetype: 'image/jpeg',
      extension: 'jpeg'
    }]
  };
};
const dressFallbackImageUrl = "".concat(cdn, "/images/racing-game/play/silks_fallback.png");
const dressInvertedFallbackImageUrl = "".concat(cdn, "/images/racing-game/play/silks_fallback_inverted.png");
const exported = {
  ImageTypes,
  getImageOptions,
  dressFallbackImageUrl,
  dressInvertedFallbackImageUrl
};
if (svs.isServer) {
  module.exports = exported;
} else {
  svs.racing.common.imageOptions = exported;
}

 })(window);